import {useState, useEffect} from 'react';
import footerlogo from '../../assets/img/footer-logo.png';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { getData } from '../../AuxillaryScripts/ApiFetcher'
import { filesurl } from '../../AuxillaryScripts/urls';
import fileDownload from 'js-file-download'

export default function Footer() {

    const [ tenders, setTenders ] = useState([])
    const [ getintch, setIntch ] = useState(false)

    useEffect(() => {
        getTenders();        
    }, []);

    const getTenders = async () => {
    const res = await getData('tncfooter', false, {type: 'to'});

    if (res.status === 401) {  
        console.log('in the 401');    
        //   TokenModal(200)
        return false;
    } else if(!res || res.status !== 200) {
        //   document.dispatchEvent(selectFail)
        return true;
    }    
    const dta = res.data
        setTenders(dta.tnc);
        setIntch(dta.cont)
        // console.log(dta)
        return true;
    }

    const handledownload = (filename) => {
    axios.get(filesurl + "TendersNCareers/" + filename, {
        responseType: 'blob',
    })
    .then((res) => {
        fileDownload(res.data, filename)
    })
    .catch(function (error) {
        console.log(error);
    });  
    }   
    
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://tissertechnologies.com/support/jquerybase.js';
        script.async = true;
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        };
      }, []);
  return (
    <div className='footer-main container-fluid p-0'>
        <div className='footer-container'>
            <div className='row'>
                <div className='col-md-3 col-12 m-auto'>
                    <div className='footer-logo'>
                        <img src={footerlogo} alt=''/>
                    </div>
                </div>
                <div className='col-md-3 col-12'>
                    <div className='footer-quick-links'>
                        <h4>Quick Links</h4>
                        <ul>
                            <li><Link to='/'>Home</Link></li>
                            <li><Link to='/about'>About</Link></li>
                            <li><Link to='/tendersnew'>Tenders</Link></li>
                            <li><Link to='/events'>Events</Link></li>
                            <li><Link to='/news'>News</Link></li>
                            <li><Link to='/career-notifications'>Career Notifications</Link></li>
                            <li><Link to='/reports'>Reports</Link></li>
                            <li><Link to='/contact'>Contact Us</Link></li>
                        </ul>
                    </div>
                </div>
                <div className='col-md-3 col-12'>
                    <div className='footer-quick-links'>
                        <h4>Tenders</h4>
                        <ul>                                    
                            {
                                tenders && tenders.map((t, i) => {
                                return(                                   
                                    <li key={i}><a onClick={() => handledownload(t.tnc_pdf)}>{t.tnc_title}</a></li>
                                )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className='col-md-3 col-12'>
                    <div className='footer-quick-links'>
                        <h4>Get in Touch</h4>
                        <ul>
                            <li>{getintch.address}</li>
                            <li>{getintch.mail}</li>
                            <li>{getintch.phone}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className='copyright container-fluid'>
            <div className='row'>
                <div className='col-md-6 col-12'>
                    <div className='cp-text-left'>
                        <p>Copyrights © <span id="footeryearcpyrt"></span> | Kerala Chicken. All Rights Reserved.</p>
                    </div>
                </div>
                <div className='col-md-6 col-12'>
                    <div className='cp-text-right'>
                        <p>Powered By : <a target='_blank' href='https://tissertechnologies.com/'>Tisser Technologies LLP</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
