import {useState, useEffect} from 'react';
import { render } from 'react-dom';
import CountUp from 'react-countup';
import { getData } from '../../AuxillaryScripts/ApiFetcher'


export default function Headerprice() {
    const [ data, setData ] = useState(null)

    const loaderMdl = new CustomEvent('trigger-modal', { detail: {}});

    const getSupply = async () => {
        const res = await getData('supplyFigures');

        if (res.status === 401) {  
          console.log('in the 401'); 
          return false;
        } else if(!res || res.status !== 200) {
          return false;
        }    
        const dta = res.data
        setData(dta)
        // console.log(dta)
        // document.dispatchEvent(loaderMdl) 
    }

    useEffect(() => {
        getSupply();
    }, [])
    
    return (
        <div className='header-price-section'>
            <div className='price-container'>
                <div className='price-box-one'>
                    <h6>Outlets</h6>
                    <h4><CountUp start={0} end={data && data.figure_outlet} separator=',' /></h4>
                </div>
                <div className='price-box-two'>
                    <h6>Farms</h6>
                    <h4><CountUp start={0} end={data && data.figure_farm} separator=','  /></h4>
                </div>
                <div className='price-box-one'>
                    <h6>Sales turnover </h6>
                    <h4><CountUp start={0} end={data && data.figure_total_kg} separator=','  /></h4>
                </div>
                <div className='price-box-two'>
                    <h6>Benefits to farm Beneficiaries</h6>
                    <h4><CountUp start={0} end={data && data.figure_bfb} separator=','  /></h4>
                </div>
                <div className='price-box-one'>
                    <h6>Benefits to outlet Beneficiaries</h6>
                    <h4><CountUp start={0} end={data && data.figure_bob} separator=','  /></h4>
                </div>
            </div>
        </div>
    )
}
