import {useState, useEffect} from 'react';
import { getData } from '../../AuxillaryScripts/ApiFetcher'
import Ticker from 'react-advanced-news-ticker';
import { Directions } from 'react-advanced-news-ticker';

export default function HomeMarquee() {
  const [ data, setData ] = useState([])
  const closeloaderMdl = new CustomEvent('close-modal', { detail: {}});

  const getSupply = async () => {
    const res = await getData('latestUpdates');

      
    const dta = res.data
    setData(dta)    
    document.dispatchEvent(closeloaderMdl)    
  }

  useEffect(() => {
      getSupply();
  }, [])

  function NewsTicker(props) {
    const { data } = props;
  
    return (
      <Ticker {...props}>
        {data.length ? (
          data.map((d) => <div key={d.updates_id}>{d.updates_desc}</div>)
        ) : (
          <div>nill</div>
        )}
      </Ticker>
    );
  }

  return (
    <div className='home-marquee'>
         <div className='inner-box'>
            <h6>Latest Updates</h6>
            <NewsTicker
                rowHeight = {48}
                maxRows = {2}
                speed = {600}
                direction = {Directions.UP}
                duration = {4000}
                autoStart = {true}
                pauseOnHover = {false}
                id = "myId"
                className = "myClassName1 myClassName2" 
                data={data}
              />
         </div>
    </div>
  )
}