import { useState } from 'react'
import { Modal } from "react-bootstrap";
import walkingchick from '../../assets/img/walkingchick.gif'

export function Walkin() {
  const [showModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] = useState({});

  const handleClose = () => setShowModal(false);

  const handleShow = ({drop = true, esc = true, centered = true }) => {
    setModalProps({drop, esc, centered });
    setShowModal(true);
  };
  

  const CustomModal = () => (
    <Modal 
      show={showModal} 
      onHide={handleClose}
      dialogClassName={'walkin-modal'}
      centered = { modalProps.centered }
      backdrop = { modalProps.drop }
      keyboard = { modalProps.esc }
      animation={false}
    >
      <Modal.Body className='d-flex justify-content-center align-items-center bg-succes'>
        <div className='w-100 h-100 bg-dange d-flex justify-content-center align-items-center'>
          <img src={walkingchick}  alt="Loading..."  className='walkinchick'/>
        </div>
      </Modal.Body>      
    </Modal>
  );

  return [CustomModal, handleShow, handleClose];
}
