import {useState, useEffect} from 'react';
import { getData } from '../../AuxillaryScripts/ApiFetcher'
import { filesurl } from '../../AuxillaryScripts/urls';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import MDimg from '../../assets/img/jafar-malik-ias.png';
import CEOimg from '../../assets/img/ceo.jpg';
import Directorone from '../../assets/img/director.jpg';
import Directortwo from '../../assets/img/director2.jpg';
import Directorthree from '../../assets/img/director3.jpg';
import MissionArrow from '../../assets/img/target-arrow.png';

export default function HomeTeam() {
  const [ data, setData ] = useState([])
  const [ members, setMembers ] = useState([])
  const to = useNavigate()
  
  const getSupply = async () => {
    const res = await getData('directorboard');

    if (res.status === 401) {  
      console.log('in the 401');    
    //   TokenModal(200)
      return false;
    } else if(!res || res.status !== 200) {
    //   document.dispatchEvent(selectFail)
      return false;
    }    
    const dta = res.data
    setData(dta)
    // console.log(dta)
  }

  const getMems = async () => {
    const res = await getData('members');

    if (res.status === 401) {  
      console.log('in the 401');    
    //   TokenModal(200)
      return false;
    } else if(!res || res.status !== 200) {
    //   document.dispatchEvent(selectFail)
      return false;
    }    
    const dta = res.data
    setMembers(dta)
    // console.log(dta)
  }

  useEffect(() => {
    getSupply();
    getMems()
 }, [])

 const characterLimiter = (str) => {
    if (str.length > 450) {
        return str.slice(0, 450) + '...';
      }
      return str;
  }

  const goToContact = () => {
    // console.log('uhhhh');
    to('/contact');
  }

  return (
    <div className='home-team container-fluid'>
        <div className="row">
            <div className='col-md-6 col-12 p-0'>
                <div className="about-team h-">
                    <h4>Team KBFPCL</h4>
                    <p>
                    {data && data.length && characterLimiter(data[0].board_desc)}
                    </p>
                    <a onClick={goToContact}>Contact Us</a>
                </div>
                <div className='mission h-'>
                    <h4>Our Mission</h4>
                    <img src={MissionArrow} alt=''/>
                    <p>{data && data.length && characterLimiter(data[0].board_mission)}</p>
                </div>
            </div>
            <div className='col-md-6 col-12 p-0'>
                <div className='board-members p-0'>
                    {/* <div className='main-member'>
                        <img src={MDimg} alt='' />
                        <h4>Shri. Jafar Malik IAS</h4>
                        <p>CHAIRMAN AND DIRECTOR - KBFPCL</p>
                        <p> (Executive Director Kudumbashree) </p>
                    </div>
                    <div className='main-member'>
                        <img src={CEOimg} alt='' />
                        <h4>Dr.Sajeev Kumar A</h4>
                        <p>Chief Executive Officer - KBFPCL</p>
                    </div>
                    <div className='director-main-box'>
                        <div className='box'>
                            <img src={Directorone} alt='' />
                            <h4>S.Prasanna Kumari</h4>
                            <p>Director - KBFPCL</p>
                        </div>
                        <div className='box'>
                            <img src={Directortwo} alt='' />
                            <h4>Usha Rani</h4>
                            <p>Director - KBFPCL</p>
                        </div>
                        <div className='box'>
                            <img src={Directorthree} alt='' />
                            <h4>Shyji Pramod</h4>
                            <p>Director - KBFPCL</p>
                        </div>
                    </div> */}
                    <div className='p-0 h-100 w-100 d-flex justify-content-center align-items-center bg-ligh'>
                        <Row className='bg-dange h-100 w-100'>
                            {
                                members.length && members.map((m) => {
                                    if (m.member_order == 1 || m.member_order == 2) {
                                        return(
                                            <Col xs={12} className='bg-inf p-3' key={m.member_id}>
                                                <div className='main-member'>
                                                    <img src={filesurl + 'BoardMembers/' + m.member_img} alt='' />
                                                    <h4>{m.member_name}</h4>
                                                    <p>{m.member_desig}</p>
                                                </div>
                                            </Col>
                                        )
                                    } else {
                                        return(
                                            <Col xs={12} sm={6} xl={4} className='bg-warnin p-3' key={m.member_id}>
                                                <div className='director-main-box'>
                                                    <div className='box'>
                                                        <img src={filesurl + 'BoardMembers/' + m.member_img} alt='' />
                                                        <h4>{m.member_name}</h4>
                                                        <p>{m.member_desig}</p>
                                                    </div>
                                                </div>
                                            </Col>                                            
                                        )
                                    }
                                })
                            }
                        </Row>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
  )
}
