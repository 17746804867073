import {useState, useEffect} from 'react';
import Header from '../../views/header/header';
import NavbarHeader from '../navbar/navbar';
import Footer from '../../views/footer/footer';
import axios from 'axios';

import { getData } from '../../AuxillaryScripts/ApiFetcher'
import { filesurl } from '../../AuxillaryScripts/urls';
import fileDownload from 'js-file-download'

export default function CareerRanklist() {

    const [ tenders, setTenders ] = useState([])

    useEffect(() => {
        getTenders();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    const getTenders = async () => {
        const res = await getData('tnc', false, {type: 'cr'});

        
        const dta = res.data
        setTenders(dta)
        const closeloaderMdl = new CustomEvent('close-modal', { detail: {}});
        document.dispatchEvent(closeloaderMdl) 
    }

    const handledownload = (filename) => {
    axios.get(filesurl + "TendersNCareers/" + filename, {
        responseType: 'blob',
    })
    .then((res) => {
        fileDownload(res.data, filename)
    })
    .catch(function (error) {
        console.log(error);
    });  
    }

  return (
    <div>
        <Header/>
        <NavbarHeader/>

        <div className='about-banner-section container-fluid'>
          <h1>Ranklist</h1>
        </div>
        
        <div className='careers-main container-fluid'>
            <div className='career-container'>
                <div className='row'>
                    {
                        tenders && tenders.map((t) => {
                            return(
                                <div className="col-md-4 col-12">
                                    <div className="t-box-main">
                                        <div className="tender-box">
                                        <h4>{t.tnc_title}</h4>
                                        <p>{t.tnc_date.split('-').reverse().join('-')}</p>
                                        <p>{t.tnc_desc}</p>
                                        <a onClick={() => handledownload(t.tnc_pdf)}>Download Ranklist</a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>

        <Footer/>

    </div>
  )
}
