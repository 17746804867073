import {useState, useEffect} from 'react';
import Header from '../../views/header/header';
import NavbarHeader from '../navbar/navbar';
import HomeSpecialitiesImg from '../../assets/img/home-specialities.jpg';
import Footer from '../../views/footer/footer';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { getData } from '../../AuxillaryScripts/ApiFetcher'
import { filesurl } from '../../AuxillaryScripts/urls';

export default function FarmSuccessStories() {

  const [ tenders, setTenders ] = useState([])
    const to = useNavigate();

    useEffect(() => {
        getTenders();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    const getTenders = async () => {
      const res = await getData('ss', false, {type: 'f'});

        
      const dta = res.data
      setTenders(dta)
      const closeloaderMdl = new CustomEvent('close-modal', { detail: {}});
      document.dispatchEvent(closeloaderMdl)
    }

    const toAlbum = (data) => {
        to('/farmstoriesdetail', {state: {data: data}});
    }

    const characterLimiter = (str) => {
      if (str.length > 150) {
          return str.slice(0, 150) + '...';
        }
        return str;
    }

  return (
    <div>
        <Header/>
        <NavbarHeader/>
        <div className='about-banner-section container-fluid'>
          <h1>Farm Success Stories</h1>
        </div>
        <div className='farm-success-stories'>
            <div className="custom-container">
              <div className='row'>              
                {
                  tenders.length && tenders.map((t) => {
                      return(
                        <div className='col-md-4' key={t.ss_id}>
                          <div onClick={() => toAlbum(t)}>
                            <div className="story-box">
                              <div className="img">
                                  <img src={filesurl + 'SuccessStories/' + t.ss_img}/>
                              </div>
                              <div className="content">
                                  <h4>{t.ss_title}</h4>
                                  <p>{characterLimiter(t.ss_desc)}</p>
                                  <a onClick={() => toAlbum(t)}>Read More</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                  })
                }
              </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}
