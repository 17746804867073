import {useState, useEffect} from 'react';
import Header from '../../views/header/header';
import NavbarHeader from '../navbar/navbar';
import HomeSpecialitiesImg from '../../assets/img/home-specialities.jpg';
import Footer from '../../views/footer/footer';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { getData } from '../../AuxillaryScripts/ApiFetcher'
import { filesurl } from '../../AuxillaryScripts/urls';


export default function OutletGalleryMain() {

    const [ tenders, setTenders ] = useState([])
    const to = useNavigate();

    useEffect(() => {
        getTenders();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    const getTenders = async () => {
        const res = await getData('gallery', false, {type: 'o'});

       
        const dta = res.data
        setTenders(dta)
        const closeloaderMdl = new CustomEvent('close-modal', { detail: {}});
        document.dispatchEvent(closeloaderMdl)
    }

    const toAlbum = (id) => {
        to('/outletgallerydetail', {state: {data: id}});
    }

  return (
    <div>
        <Header/>
        <NavbarHeader/>

        <div className='tender-banner-section container-fluid'>
          <h1>Outlet Gallery</h1>
        </div>

        <div className='gallery-main container-fluid'>
            <div className='gallery-container'>
                <div className='row'>
                    {
                        tenders.length && tenders.map((t) => {
                            return(
                                <div className='col-md-4 col-12' key={t.gal_id}>
                                    <div onClick={() => toAlbum(t.gal_id)}>
                                        <div className='gallery-box'>
                                            <img src={filesurl + 'Gallery/' + t.gal_thumb}/>
                                            <h4>{t.gal_alb}</h4>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>

        <Footer/>

    </div>
  )
}
