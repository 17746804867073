import {useState, useEffect} from 'react';
import { getData } from '../../AuxillaryScripts/ApiFetcher'
import { filesurl } from '../../AuxillaryScripts/urls';
import { useNavigate } from 'react-router-dom';
import Header from '../../views/header/header';
import NavbarHeader from '../navbar/navbar';
import HomeNewsOne from '../../assets/img/news1.jpg';
import HomeNewsTwo from '../../assets/img/news2.jpg';
import Footer from '../../views/footer/footer';
import { Link } from 'react-router-dom';


export default function NewsMain() {

  const [ data, setData ] = useState([])
  const to = useNavigate()
  const closeloaderMdl = new CustomEvent('close-modal', { detail: {}});

  useEffect(() => {
    getSupply();
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  
  const getSupply = async () => {
    const res = await getData('nne', false, {type: 'n'});

       
    const dta = res.data
    setData(dta)
    document.dispatchEvent(closeloaderMdl)   
  }

  const characterLimiter = (str) => {
    if (str.length > 80) {
        return str.slice(0, 80) + '...';
      }
      return str;
  }

  const toNewsDetail = (data) => {
    to('/newsdetail', {state: {data: data}})
  }

  return (
    <div>
        <Header/>
        <NavbarHeader/>

        <div className='about-banner-section container-fluid'>
          <h1>News</h1>
        </div>

        <div className='news-main container-fluid'>
          <div className='news-container'>
            <div className='row'>
              <div className='col-12'>
                <div className='news-main-box'>
                  <div className='row'>
                  {
                      data.length && data.map((d) => {
                        return(
                          <div className='col-md-4 col-xs-12 col-6' key={d.nne_id}>
                            <a onClick={() => toNewsDetail(d)}>
                              <div className='inner-box'>
                                  <div className='img-box'>
                                      
                                      {d.nne_img_type == 1 ? 
                                      <img src={filesurl + 'NewsNEvents/' + d.nne_img} alt=''/> : 
                                      <iframe className='h w-100' src={d.nne_img} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                      }
                                      <h6>{d.nne_date.split('-').reverse().join('-')}</h6>
                                  </div>
                                  <p>{characterLimiter(d.nne_title)}</p>
                              </div>
                            </a>
                          </div>
                        )
                      })                    
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer/>

    </div>
  )
}
