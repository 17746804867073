import {useState, useEffect} from 'react';
import Header from '../../views/header/header';
import NavbarHeader from '../navbar/navbar';
import DownloadBtn from '../../assets/img/download.png';
import Footer from '../../views/footer/footer';
import axios from 'axios';

import { getData } from '../../AuxillaryScripts/ApiFetcher'
import { filesurl } from '../../AuxillaryScripts/urls';
import fileDownload from 'js-file-download'

export default function OutletApplicationDownload() {

    const [ tenders, setTenders ] = useState([])

    useEffect(() => {
        getTenders();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    const getTenders = async () => {
        const res = await getData('ptd', false, {type: 'ao'});

        
        const dta = res.data
        setTenders(dta)
        const closeloaderMdl = new CustomEvent('close-modal', { detail: {}});
        document.dispatchEvent(closeloaderMdl)
    }

    const handledownload = (filename) => {
        // alert('hola')
    axios.get(filesurl + "Ptd/" + filename, {
        responseType: 'blob',
    })
    .then((res) => {
        fileDownload(res.data, filename)
    })
    .catch(function (error) {
        console.log(error);
    });  
    }

  return (
    <div>
        <Header/>
        <NavbarHeader/>
        <div className='about-banner-section container-fluid'>
          <h1>Application for Outlet</h1>
        </div>
        <div className='brochure-download-main'>
            <div class="custom-container">
                <div className='row'>
                    {
                        tenders && tenders.map((t) => {
                            return(
                                <div className='col-md-4 col-12 m-auto' key={t.ptd_id}>
                                    <a onClick={() => handledownload(t.ptd_pdf)}>
                                        <div className="download-box">
                                            <div className="row">
                                                <div className="col-md-9 col-8">
                                                    <div className="content">
                                                        <h4>{t.ptd_title}</h4>
                                                        <p>{t.ptd_date.split('-').reverse().join('-')}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-8">
                                                    <div className="img">
                                                        <img src={DownloadBtn}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}
