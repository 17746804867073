import { useState, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { TiTick } from "react-icons/ti";

import Kc from '../../assets/img/kc.jpg'

import { postData } from "../../AuxillaryScripts/ApiFetcher";

export default function HomeForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [msg, setMsg] = useState("");
  const [spin, setSpin] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);

  const enquire = async () => {
    const res = await postData("enquiry", false, {
      mail: email,
      nme: name,
      msg: msg,
      phn: phone,
    });

    setName("");
    setEmail("");
    setPhone("");
    setMsg("");

    if (!res || res.status !== 201) {
      //   document.dispatchEvent(selectFail)
      setSpin(false);
      return false;
    }
    setSpin(false);
    return true;
  };

  const hadleSubmit = async () => {
    setSpin(true);
    const stat = await enquire();

    if (stat) setSuccess(true);
    else setFail(true);
  };

  useEffect(() => {
    success &&
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
  }, [success]);

  useEffect(() => {
    fail &&
      setTimeout(() => {
        setFail(false);
      }, 2000);
  }, [fail]);

  return (
    <div className="home-form container-fluid">
      <div className="row">
        <div className="col-md-5 col-12 p-0">
          <div className="form-box">
            <h4>Enquiry</h4>
            <Form>
              <Form.Control
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="Your name"
              />
              <Form.Control
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Email"
              />
              <Form.Control
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                type="tel"
                placeholder="Phone"
              />
              <Form.Control
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
                type="textarea"
                placeholder="Message"
                rows="4"
                cols="50"
              />

              {success ? (
                <Button className="success-btn">Success</Button>
              ) : fail ? (
                <Button className="failed-btn">Try again in 2 seconds</Button>
              ) : (
                <Button className="enq-submit-btn" onClick={hadleSubmit}>
                  {spin ? (
                    <Spinner
                      animation="border"
                      size="sm"
                      className="enq-spin"
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              )}
            </Form>
          </div>
        </div>

        <div className="col-md-7 col-12">
          <img src={Kc} className="h- w-" alt="keralachicken" />          
        </div>
      </div>
    </div>
  );
}
