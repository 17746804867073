import React from 'react';
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import BrochurePDF from '../../assets/img/brochure.pdf';
import LeafletPDF from '../../assets/img/leaflet.pdf';
import {FaFacebookSquare} from "react-icons/fa";
import {FaInstagram} from "react-icons/fa";
import {FaYoutubeSquare} from "react-icons/fa";



export default function NavbarHeader() {
  return (
    <div className='header-navbar-main'>
        
          <Navbar expand="lg" className='navbar-main'>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                  <Nav.Link><Link to="/">Home</Link></Nav.Link>
                  <NavDropdown title="About Us" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/about">About</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/directboardmembers">Direct board members</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/brochures">Brochure</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/leaflet">Leaflets</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Farms" id="basic-nav-dropdown">
                    <NavDropdown.Item as={Link} to="/farmguidelines">Guideline</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/applicationforfarm">Application for farm</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/farmgallery">Gallery</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/farmhandbooks">Handbooks</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Outlets" id="basic-nav-dropdown">
                    <NavDropdown.Item as={Link} to="/outletguidelines">Guideline</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/outletapplication">Application for outlet</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/outletgallery">Gallery</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Success Stories" id="basic-nav-dropdown">
                    <NavDropdown.Item as={Link} to="/farmsuccessstories">Farms</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/outletsuccessstories">Outlet Beneficiaries</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link as={Link} to="/events">Events</Nav.Link>
                  <Nav.Link as={Link} to="/reports">Reports</Nav.Link>
                  <NavDropdown title="Careers" id="basic-nav-dropdown">
                    <NavDropdown.Item as={Link} to="/career-notifications">Notifications</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/career-ranklist">Ranklist</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Tenders" id="basic-nav-dropdown">
                    <NavDropdown.Item as={Link} to="/tendersnew">New</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/tendersclosed">Closed Tenders</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link as={Link} to="/news">News</Nav.Link>
                  <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
              </Nav>
              <div className='icon-box-mob'>
                  <ul>
                      <li><Link to=''>Enquiry</Link></li>
                      <li><Link to=''><FaFacebookSquare/></Link></li>
                      <li><Link to=''><FaInstagram/></Link></li>
                      <li><Link to=''><FaYoutubeSquare/></Link></li>
                  </ul>
              </div>
              </Navbar.Collapse>
          </Navbar>
          
        
    </div>
  )
}
