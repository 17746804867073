import {useState, useEffect} from 'react';
import Header from '../../views/header/header';
import NavbarHeader from '../navbar/navbar';
import Footer from '../../views/footer/footer';
import axios from 'axios';

import { getData } from '../../AuxillaryScripts/ApiFetcher'
import { filesurl } from '../../AuxillaryScripts/urls';
import fileDownload from 'js-file-download'

export default function TenderNewMain() {
    const [ tenders, setTenders ] = useState([])

    useEffect(() => {
        getTenders();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
    const closeloaderMdl = new CustomEvent('close-modal', { detail: {}});

    const getTenders = async () => {
    const res = await getData('tnc', false, {type: 'to'});

     
    const dta = res.data
        setTenders(dta)
        document.dispatchEvent(closeloaderMdl)        
    }

    const handledownload = (filename) => {
    axios.get(filesurl + "TendersNCareers/" + filename, {
        responseType: 'blob',
    })
    .then((res) => {
        fileDownload(res.data, filename)
    })
    .catch(function (error) {
        console.log(error);
    });  
    }

  return (
    <div>
        <Header/>
        <NavbarHeader/>

        <div className='tender-banner-section container-fluid'>
          <h1>Tenders</h1>
        </div>

        <div className='tender-main container-fluid'>
            <div className="tender-container">
                <div className="row">
                    {
                        tenders && tenders.map((t) => {
                        return(
                            <div className="col-md-4 col-12">
                                <div className="t-box-main">
                                    <div className="tender-box">
                                    <h4>{t.tnc_title}</h4>
                                    <p>{t.tnc_date.split('-').reverse().join('-')}</p>
                                    <p>{t.tnc_desc}</p>
                                    <a onClick={() => handledownload(t.tnc_pdf)}>Download</a>
                                    </div>
                                </div>
                            </div>
                        )
                        })
                    }
                    {/* <div className="col-md-4 col-12">
                        <div className="t-box-main">
                        <div className="tender-box">
                            <h4>Tenders</h4>
                            <p>Feed Suppy</p>
                            <p>Chick Suppl</p>
                            <p>Invitation for Vehicle on Contract</p>
                            <p>Appointment of External Auditor</p>
                            <a href='#'>View More</a>
                        </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>

        <Footer/>

    </div>
  )
}
