function FileValidator(file) {
  const selectedFileSize = file.size;
  const selectedFileFormat = file.name.split('.').pop().toLowerCase();
  // check if file format is allowed and file size is less than the maximum size
  if (selectedFileFormat === 'pdf' && selectedFileSize <= 1048576) {
    console.log('Selected file is of the allowed format and size.');
    // Do something with the file, such as upload to a server
    return true;
  } else {
    console.log('Selected file is not of the allowed format or size.');
    return false;
  }
}
//"homepage": "https://tissertechnologies.com/Projects/2023/design/keralachicken/",

function ImageValidator(file) {
  const selectedFileSize = file.size;
  const selectedFileFormat = file.name.split('.').pop().toLowerCase();
  const types = ['jpg', 'png', 'jpeg', 'webp']
  // check if file format is allowed and file size is less than the maximum size
  if (types.includes(selectedFileFormat) && selectedFileSize <= 307200) {
    console.log('Selected file is of the allowed format and size.');
    // Do something with the file, such as upload to a server
    return true;
  } else {
    console.log('Selected file is not of the allowed format or size.');
    return false;
  }
}

export { FileValidator, ImageValidator};

