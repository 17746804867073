import {useState, useEffect} from 'react';
import { getData } from '../../AuxillaryScripts/ApiFetcher'
import CPImg from '../../assets/img/chicken-price.png';
import Ticker from 'react-advanced-news-ticker';
import { Directions } from 'react-advanced-news-ticker';

export default function HomeSliderPrice() {
  const [ data, setData ] = useState([])

  const getSupply = async () => {
    const res = await getData('todaysRate');

    if (res.status === 401) {  
      // console.log('in the 401');    
    //   TokenModal(200)
      return false;
    } else if(!res || res.status !== 200) {
    //   document.dispatchEvent(selectFail)
      return false;
    }    
    const dta = res.data
    setData(dta)
    // console.log(dta)
  }

  useEffect(() => {
      getSupply();
  }, [])

  function NewsTicker(props) {
    const { data } = props;
  
    return (
      <Ticker {...props}>
        {data.length ? (
          data.map((d, ind) => <div key={ind} className='row price-row'>
          <div className='col-8'>
            <p>{d.dist}</p>
          </div>
          <div className='col-4'>
            <p>₹{d.rate}</p>
          </div>
        </div>)
        ) : (
          <div className='row price-row'>
          <div className='col-8'>
            <p>nill</p>
          </div>
          <div className='col-4'>
            <p>nill</p>
          </div>
        </div>
        )}
      </Ticker>
    );
  }

  return (
    <div className='home-slider-price'>
        <div className='inner-box'>
          <h4>Todays Rate</h4>
          <NewsTicker
            rowHeight = {48}
            maxRows = {2}
            speed = {600}
            direction = {Directions.UP}
            duration = {4000}
            autoStart = {true}
            pauseOnHover = {false}
            id = "myId"
            className = "myClassName1 myClassName2"
            data={data} 
          />                            
        </div>
    </div>
  )
}
