import { useEffect } from "react";
import { ImageValidator, FileValidator } from "./FileValidator";

const inputs = {
  abt_cmp1: [['abt_cmp1_title','abt_cmp1_desc','abt_cmp1_img'],['slider_byline','slider_words','slider_image']],
  abt_cmp2: [['abt_cmp2_title','abt_cmp2_desc','abt_cmp2_img'],['slider_byline','slider_words','slider_image']]
};

const getIds = (compnt) => {
  return inputs[compnt];
}

const getInputs = (compnt) => {
  let ids = inputs[compnt];

  const data = {};

  for (let index = 0; index < ids[0].length; index++) {    
    const inputElement = document.querySelector("#" + ids[0][index]);
    if(inputElement.getAttribute("type") === "file") {
      if(inputElement.files[0].name.split('.').pop().toLowerCase() === 'pdf') {
        if(!FileValidator(inputElement.files[0])){
          return false
        }
      }else {
        if(!ImageValidator(inputElement.files[0])){
          return false
        }
      }
      data[ids[1][index]] = inputElement.files[0];
    }else {
      data[ids[1][index]] = inputElement.value;
    }
  }
  return data;
}

const setInputs = (compnt) => {
  let ids = inputs[compnt];

  for (let index = 0; index < ids[0].length; index++) {    
    const inputElement = document.querySelector("#" + ids[0][index]);
    inputElement.value = "";
  }
}


//======================================================================================================================================


var allInps = {
  aboutcomp: {},
  aboutcomp2: {},
  login: {},
  tockenrefresh: {},
  todaysrate: {},
  latest: {},
  supply: {},
  slider: {},
  about: {},
  mem: {},
  team: {},
  mission: {},
  bro: {},
  leaf: {},
  appli: {},
  gal: {},
  sucs: {},
  nne: {},
  tnc: {},
  contact: {},
  album: {},
  alloutlets: {},
  alloutletsedit: {},
}

const fileFail = new CustomEvent('trigger-modal', { detail: {
  header: <span className="error-head">Error</span>,
  body: <p className="error-msg">The selected file is not valid.</p>,
  footer: null,
  class: "error-modal"
}});

const inputFail = new CustomEvent('trigger-modal', { detail: {
  header: <span className="error-head">Error</span>,
  body: <p className="error-msg">This input is not defined !!!</p>,
  footer: null,
  class: "error-modal"
}});

const handleEachInps = (e) => {
  let elm = e.target;  
  let key = elm.id;

  // console.log(elm)
  // console.log(key)

  if (key) {
    var keystatus = false;

    if(key)
      for (const k in allInps) {
        // console.log(key);    
        if(key.split('-')[0] === k) {      
          keystatus = true;
        }
      }

    if(!keystatus) {
      // document.dispatchEvent(inputFail);
      for (const k in allInps) {
        allInps[k] = {};
      }     
      return false
    }
      

    if (elm.type === "file" && elm.files[0]) {

      if(elm.files[0].name.split('.').pop().toLowerCase() === 'pdf') {
        if(!FileValidator(elm.files[0])){
          document.dispatchEvent(fileFail);
          return false
        }
      }else {
        if(!ImageValidator(elm.files[0])){
          document.dispatchEvent(fileFail);
          return false;
        }
      }

      allInps[key.split('-')[0]][key.split('-').slice(1).join("-")] = elm.files[0];

    } else {

      allInps[key.split('-')[0]][key.split('-').slice(1).join("-")] = elm.value;

    }

    console.log(allInps); 
  }  
}

document.addEventListener('input', handleEachInps);


const getAllInps = (comp = false) => {
  if(comp)
    return allInps[comp];
}

const setAllInpToNull = (comp = false) => {
  if(comp)
    allInps[comp] = {};
  console.log(allInps);
}

const setAllInpFieldToNull = (comp = false) => {
  if(comp) {
    if(Object.keys(allInps[comp]).length) {
      Object.keys(allInps[comp]).map((k) => {
        const inputElement = document.querySelector("#" + comp + "-" + k);
        inputElement.value = "";
      })    
    }
  }
}

const setAnInpField = (data, number = true) => {  
  for (const key in data) {    
    document.querySelector("#" + key).value = number ? Number(data[key]) : data[key];
  }
}


//======================================================================================================================================




export { getIds, getInputs, setInputs, getAllInps, setAllInpToNull, setAllInpFieldToNull, setAnInpField };