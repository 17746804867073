// export const baseurl = 'http://192.168.1.47:8000/chick/'; //test_chick
// export const baseurl = 'http://localhost:8000/'; //test_chick
// export const filesurl = 'http://192.168.1.47:8000/files/';

export const baseurl = 'https://keralachicken.org.in/keralachicken/api/chick/';
export const filesurl = 'https://keralachicken.org.in/keralachicken/api/public/files/';

// export const baseurl = 'https://webdesignilluminati.in/projectsNew/keralachicken/chick/';
// export const filesurl = 'https://webdesignilluminati.in/projectsNew/keralachicken/public/files/';

function Base () {
  return baseurl;
}

function Files () {
  return filesurl;
}

export { Base, Files };