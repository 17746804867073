import React ,{ useEffect }from 'react'
import { Routes, Route } from "react-router-dom";
import AboutMain from '../components/about/about';
import Home from '../components/home/home';
import TenderNewMain from '../components/tenders-new/tenders-new';
import OutletsMain from '../components/outlets/outlets';
import ReportsMain from '../components/reports/reports';
import NewsMain from '../components/news/news';
import NewsDetailMain from '../components/newsdetail/newsdetail';
import ContactMain from '../components/contact/contact';
import DirectorBoardMain from '../components/director-board/director-board';
import FarmGuideline from '../components/farm-guidelines/farm-guideline';
import BrochureDownloadMain from '../components/brochure/brochure';
import LeafletDownloadMain from '../components/leaflets/leaflets';
import CareerNotifications from '../components/careers-notifications/careers-notifications';
import CareerRanklist from '../components/careers-ranklist/careers-ranklist';
import TenderClosedMain from '../components/tenders-closed/tenders-closed';
import OutletGuideline from '../components/outlet-guidelines/outlet-guideline';
import FarmApplicationDownload from '../components/farm-application/farm-application';
import FarmGalleryMain from '../components/farm-gallery/farm-gallery';
import FarmGalleryDetailMain from '../components/farm-gallery-detail/farm-gallery-detail';
import OutletGalleryMain from '../components/outlet-gallery/outlet-gallery';
import OutletGalleryDetailMain from '../components/outlet-gallery-detail/outlet-gallery-detail';
import EventsMain from '../components/events/events';
import EventsDetailMain from '../components/eventsdetail/eventsdetail';
import FarmSuccessStories from '../components/farm-success-stories/farm-success-stories';
import FarmSuccessStoriesDetail from '../components/farm-stories-detail/farm-stories-detail';
import OutletSuccessStories from '../components/outlet-success-stories/outlet-success-stories';
import OutletSuccessStoriesDetail from '../components/outlet-stories-detail/outlet-stories-detail';
import HandbooksMain from '../components/handbooks/handbooks';
import OutletApplicationDownload from '../components/outlet-application/outlet-application';
import Footer from './footer/footer';
import Header from './header/header';
import NavbarHeader from './navbar/navbar';
import { Walkin } from '../components/Common/Walkin';
import { useLocation } from 'react-router-dom';

export default function MainLayout() {
  const [CustomModal, handleShow, handleClose] = Walkin();
  const loc = useLocation();
  const loaderMdl = new CustomEvent('trigger-modal', { detail: {}});
  

  useEffect(() => {
    const handleShowModal = (e) => {
      handleShow(e.detail)
    };
    document.addEventListener('trigger-modal', handleShowModal);
    return () => {
      document.removeEventListener('trigger-modal', handleShowModal);
    };
  }, []);

  useEffect(() => {
    const handleCloseModal = (e) => {
      // console.log(e);
      handleClose()
    };
    document.addEventListener('close-modal', handleCloseModal);
    return () => {
      document.removeEventListener('close-modal', handleCloseModal);
    };
  }, []);

  useEffect(() => {
    // if (loc.pathname == '/' || loc.pathname == '/about' 
    // || loc.pathname == '/tendersnew' || loc.pathname == '/farmgallery' 
    // || loc.pathname == '/farmgallerydetail' || loc.pathname == '/outlets' 
    // || loc.pathname == '/reports' || loc.pathname == '/career-notifications' 
    // || loc.pathname == '/news' || loc.pathname == '/newsdetail' 
    // || loc.pathname == '/contact' || loc.pathname == '/directboardmembers' 
    // || loc.pathname == '/farmguidelines' || loc.pathname == '/brochures' 
    // || loc.pathname == '/leaflet' || loc.pathname == '/career-ranklist' 
    // || loc.pathname == '/tendersclosed' || loc.pathname == '/outletguidelines' 
    // || loc.pathname == '/applicationforfarm' || loc.pathname == '/outletgallery' 
    // || loc.pathname == '/outletgallerydetail' || loc.pathname == '/events' 
    // || loc.pathname == '/eventsdetail' || loc.pathname == '/farmsuccessstories' 
    // || loc.pathname == '/farmstoriesdetail' || loc.pathname == '/outletsuccessstories'
    // || loc.pathname == '/outletstoriesdetail' || loc.pathname == '/farmhandbooks'
    // || loc.pathname == '/outletapplication') {
      
    // }
    document.dispatchEvent(loaderMdl)
    // console.log(loc.pathname);
  }, [loc.pathname])

  
  return (
    <>
      <CustomModal/>
      <Routes>
        <Route exact path='/' element={< Home />}></Route>
        <Route exact path='/about' element={<AboutMain/>}></Route>
        <Route exact path='/tendersnew' element={< TenderNewMain />}></Route>
        <Route exact path='/farmgallery' element={< FarmGalleryMain />}></Route>
        <Route exact path='/farmgallerydetail' element={< FarmGalleryDetailMain />}></Route>
        <Route exact path='/outlets' element={< OutletsMain />}></Route>
        <Route exact path='/reports' element={< ReportsMain />}></Route>
        <Route exact path='/career-notifications' element={< CareerNotifications />}></Route>
        <Route exact path='/news' element={< NewsMain />}></Route>
        <Route exact path='/newsdetail' element={< NewsDetailMain />}></Route>
        <Route exact path='/contact' element={< ContactMain />}></Route>
        <Route exact path='/directboardmembers' element={< DirectorBoardMain />}></Route>
        <Route exact path='/farmguidelines' element={< FarmGuideline />}></Route>
        <Route exact path='/brochures' element={< BrochureDownloadMain />}></Route>
        <Route exact path='/leaflet' element={<LeafletDownloadMain/>}></Route>
        <Route exact path='/career-ranklist' element={<CareerRanklist/>}></Route>
        <Route exact path='/tendersclosed' element={<TenderClosedMain/>}></Route>
        <Route exact path='/outletguidelines' element={<OutletGuideline/>}></Route>
        <Route exact path='/applicationforfarm' element={<FarmApplicationDownload/>}></Route>
        <Route exact path='/outletgallery' element={<OutletGalleryMain/>}></Route>
        <Route exact path='/outletgallerydetail' element={<OutletGalleryDetailMain/>}></Route>
        <Route exact path='/events' element={<EventsMain/>}></Route>
        <Route exact path='/eventsdetail' element={<EventsDetailMain/>}></Route>
        <Route exact path='/farmsuccessstories' element={<FarmSuccessStories/>}></Route>
        <Route exact path='/farmstoriesdetail' element={<FarmSuccessStoriesDetail/>}></Route>
        <Route exact path='/outletsuccessstories' element={<OutletSuccessStories/>}></Route>
        <Route exact path='/outletstoriesdetail' element={<OutletSuccessStoriesDetail/>}></Route>
        <Route exact path='/farmhandbooks' element={<HandbooksMain/>}></Route>
        <Route exact path='/outletapplication' element={<OutletApplicationDownload/>}></Route>
      </Routes>
    </>        
  )
}
