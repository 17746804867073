import React from 'react';
import kclogo from '../../assets/img/kc-logo.png';
import klogo from '../../assets/img/k-logo.png';
import { Link } from 'react-router-dom';
import {FaFacebookSquare} from "react-icons/fa";
import {FaInstagram} from "react-icons/fa";
import {FaYoutubeSquare} from "react-icons/fa";

export default function Header() {
  return (
    <div className='header-main navbar-fixed-top'>
        <div className='k-logo'>
            <img src={klogo}/>
        </div>
        <div className='kc-logo'>
            <Link to='/'><img src={kclogo}/></Link>
        </div>
        <div className="price-box-main">
            <div className='icon-box'>
                <ul>
                    <li><Link to='/contact'>Enquiry</Link></li>
                    <li>
                        <a href='https://m.facebook.com/profile.php/?id=100063733882775&name=xhp_nt__fblite__profile__tab_bar' target="_blank" rel="noopener noreferrer">
                            <FaFacebookSquare/>                                
                        </a>
                    </li>
                    <li>
                        <a href='https://instagram.com/kerala_chicken?igshid=MzNlNGNkZWQ4Mg==' target="_blank" rel="noopener noreferrer">
                            <FaInstagram/>                               
                        </a>
                    </li>                    
                    <li>
                        <a href='https://youtu.be/XO7PYuPYk54' target="_blank" rel="noopener noreferrer">
                            <FaYoutubeSquare/>                             
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  )
}
