import {useState, useEffect} from 'react';
import { getData } from '../../AuxillaryScripts/ApiFetcher'
import { filesurl } from '../../AuxillaryScripts/urls';
import Carousel from 'react-bootstrap/Carousel';
import sliderone from '../../assets/img/slider.jpg';

export default function HomeCarousel() {

  const [ data, setData ] = useState(null)

    const getSupply = async () => {
        const res = await getData('slider');

        if (res.status === 401) {  
          console.log('in the 401');    
        //   TokenModal(200)
          return false;
        } else if(!res || res.status !== 200) {
        //   document.dispatchEvent(selectFail)
          return false;
        }    
        const dta = res.data
        setData(dta)
        // console.log(dta)
    }

    useEffect(() => {
        getSupply();
    }, [])

  return (
    <Carousel>      
      {
        data && data.map((d) => {
          return(
          <Carousel.Item key={d.slider_id}>
            <img
              className="d-block w-100"
              // src={sliderone}
              src={filesurl + 'HomeSlider/' + d.slider_img}
              alt="First slide"
            />
          </Carousel.Item>
          )
        })
      }
    </Carousel>
  )
}
