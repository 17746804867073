import {useState, useEffect} from 'react';
import { getData } from '../../AuxillaryScripts/ApiFetcher'
import { filesurl } from '../../AuxillaryScripts/urls';
import { useNavigate } from 'react-router-dom';
import HomeNewsOne from '../../assets/img/news1.jpg';
import HomeNewsTwo from '../../assets/img/news2.jpg';


export default function HomeNews() {
  const [ data, setData ] = useState([])
  const to = useNavigate()

  const getSupply = async () => {
    const res = await getData('nnehome', false, {type: 'n', limit: 2});

    if (res.status === 401) {  
      console.log('in the 401');    
    //   TokenModal(200)
      return false;
    } else if(!res || res.status !== 200) {
    //   document.dispatchEvent(selectFail)
      return false;
    }    
    const dta = res.data
    setData(dta)
    // console.log(dta)
  }
  useEffect(() => {
    getSupply();
  }, [])

  const characterLimiter = (str) => {
    if (str.length > 80) {
        return str.slice(0, 80) + '...';
      }
      return str;
  }

  const toNewsDetail = (data) => {
    to('/newsdetail', {state: {data: data}})
  }

  return (
    <div className='home-news container-fluid'>
        <div className='news-container'>
          <div className='row'>
            <div className='col-md-7 col-12'>
              <div className='news-main-box'>
                <h4>News & Events</h4>
                <div className='row'>

                  {data.length && <div className='col-md-6 col-xs-12 col-6' data-aos="fade-up" data-aos-delay="100">
                    <div onClick={() => toNewsDetail(data[0])}>
                      <div className='inner-box'>
                        <div className='img-box'>
                          {data[0].nne_img_type == 1 ? 
                            <img src={filesurl + 'NewsNEvents/' + data[0].nne_img} alt=''/> : 
                            <iframe className='w-100 ' src={data[0].nne_img} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            }
                          <h6>{data[0].nne_date.split('-').reverse().join('-')}</h6>
                        </div>
                        <p>{characterLimiter(data[0].nne_title)}</p>
                      </div>
                    </div>
                  </div>}

                  {data.length == 2 && <div className='col-md-6 col-xs-12 col-6' data-aos="fade-up" data-aos-delay="200">
                    <div onClick={() => toNewsDetail(data[1])}>
                      <div className='inner-box'>
                        <div className='img-box'>
                          {data[1].nne_img_type == 1 ? 
                            <img src={filesurl + 'NewsNEvents/' + data[1].nne_img} alt=''/> : 
                            <iframe src={data[1].nne_img} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            }
                          <h6>{data[1].nne_date.split('-').reverse().join('-')}</h6>
                        </div>
                        <p>{characterLimiter(data[1].nne_title)}</p>
                      </div>
                    </div>
                  </div>}

                  {/* <div className='col-md-6 col-xs-12 col-6' data-aos="fade-up" data-aos-delay="200">
                    <Link to="/newsdetail">
                      <div className='inner-box'>
                        <div className='img-box'>
                          <img src={HomeNewsTwo} alt=''/>
                          <h6>26-07-2022</h6>
                        </div>
                        <p>Lorem ipsum dolor sitamet consectetur adipiscing elitnulla convallis</p>
                      </div>
                    </Link>
                  </div> */}

                </div>
              </div>
            </div>
            <div className='col-md-5 col-12' data-aos="fade-up" data-aos-delay="300">
              <div className='yt-video'>
                <iframe src="https://www.youtube.com/embed/gtcQFhRUdl4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}
