import React from 'react';
import Header from '../../views/header/header';
import NavbarHeader from '../navbar/navbar';
import Footer from '../../views/footer/footer';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getData } from '../../AuxillaryScripts/ApiFetcher'

export default function OutletsMain() {

  useEffect(() => {
    getOutlets()
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  const closeloaderMdl = new CustomEvent('close-modal', { detail: {}});
  const loc = useLocation(); 
  const [ outlets, setOutlets ] = useState([])
  
  const getOutlets = async () => {
    const res = await getData('outlets', false, {search: loc.state.data});

    
    const dta = res.data
    setOutlets(dta)
    document.dispatchEvent(closeloaderMdl)   
  }

  return (
    <div>
        <Header/>
        <NavbarHeader/>

        <div className='about-banner-section container-fluid'>
          <h1>Outlets</h1>
        </div>

        <div className='outlets-main container-fluid'>
          <div className='outlet-container'>
            <div className='row'>

              {outlets.map((d) => {
                return (
                  <div className='col-md-4 col-12' key={d.out_id}>
                    <div className='outlet-box'>
                      <iframe /*src={d.out_map}*/ src={"https://maps.google.com/maps?q=" + d.out_lat + "," + d.out_long + "&hl=en&z=14&output=embed"} frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                      <div className='content'>
                        <h4>{d.out_dist ? d.out_dist.charAt(0).toUpperCase()  + d.out_dist.slice(1) + ',' : ''} {d.out_taluk ? d.out_taluk.charAt(0).toUpperCase() + d.out_taluk.slice(1)  + ',' : ''} {d.out_local}</h4>
                        <a href='#'>{d.out_phone}</a>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <Footer/>

    </div>
  )
}
