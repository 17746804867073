import React from 'react';
//import Headerprice from '../header-price/header-price';
import { getData } from '../../AuxillaryScripts/ApiFetcher'
import { filesurl } from '../../AuxillaryScripts/urls';
import Header from '../../views/header/header';
import NavbarHeader from '../navbar/navbar';
import MainAboutImg from '../../assets/img/main-about.png';
import FigImg1 from '../../assets/img/figure73.png';
import FigImg2 from '../../assets/img/figure74.png';
import Footer from '../../views/footer/footer';
import { useEffect, useState } from 'react';


export default function AboutMain() {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  const [ data, setData ] = useState([])
  const closeloaderMdl = new CustomEvent('close-modal', { detail: {}});
    
  const getSupply = async () => {
    const res = await getData('about');
 
    const dta = res.data
    setData(dta)
    document.dispatchEvent(closeloaderMdl)
    // console.log(dta)
  }

  useEffect(() => {
    getSupply();
  }, [])

  return (
    <div>
      <Header/>
      <NavbarHeader/>

      <div className='about-banner-section container-fluid'>
          <h1>About Us</h1>
      </div>

      <div className='main-about container-fluid'>
        <div className='about-container'>
          <div className='row'>
            <div className='col-md-7 col-12 m-auto'>
              <div className='about-content'>
                <h4>Kerala Chicken</h4>
                <p>{data.length ? data[0].about_desc : null}
                </p>
              </div>
            </div>
            <div className='col-md-5 col-12'>
              <div className='about-img'>
                <img className='figimg1' src={FigImg1}/>
                <img className='figimg2' src={FigImg2}/>
                <img className='main-img' src={data.length ? filesurl + 'AboutUs/' + data[0].about_img : MainAboutImg}/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer/>

    </div>
  )
}
