import {useState, useEffect} from 'react';
import { getData } from '../../AuxillaryScripts/ApiFetcher'
import { filesurl } from '../../AuxillaryScripts/urls';
import { Form, Toast } from 'react-bootstrap';
import fileDownload from 'js-file-download'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import InputGroup from 'react-bootstrap/InputGroup';
import { AiOutlineSearch } from "react-icons/ai";

export default function HomeTender() {

  const [ data, setData ] = useState(null)
  const [ outlets, setOutlets ] = useState([])
  const [ tenders, setTenders ] = useState([])
  const [ search, setSearch ] = useState('')
  const [show, setShow] = useState(false);
  const to = useNavigate()
  const toggleshow = () => setShow(true);
  const toggleClose = () => setShow(false);
  const closeloaderMdl = new CustomEvent('close-modal', { detail: {}});

  const getSupply = async () => {
    const res = await getData('todaysRate');

    if (res.status === 401) {  
      console.log('in the 401');    
    //   TokenModal(200)
      return false;
    } else if(!res || res.status !== 200) {
    //   document.dispatchEvent(selectFail)
      return false;
    }    
    const dta = res.data
    setData(dta)
    // console.log(dta)
  }

  const getTenders = async () => {
    const res = await getData('tnchome');

    
    const dta = res.data
    setTenders(dta)
    // document.dispatchEvent(closeloaderMdl)    
    return true;
  }

  const districts = [
    'Ernakulam',
    'Kannur',
    'Kollam',
    'Kozhikode',
    'Palakkad',
    'Thiruvananthapuram',
    'Wayanad',
    'Alappuzha',
    'Idukki',
    'Kasaragod',
    'Kottayam',
    'Malappuram',
    'Pathanamthitta',
    'Thrissur',
  ];

  const handledownload = (filename) => {
    axios.get(filesurl + "TendersNCareers/" + filename, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename)
    })
    .catch(function (error) {
      console.log(error);
  });  
  }

  // useEffect(() => {
  //   outlets && to('/outlets', {state: {
  //     data: outlets
  //   }})
  // }, [outlets])

  const hsndleOutletShow = async () => {
    search && to('/outlets', {state: {
      data: search
    }})
  }

  useEffect(() => {
      getTenders();
      getSupply();
  }, [])

  return (
    <div className='home-tender-main container-fluid'>
      <div className="tender-container">
        <div className="row">
          
          {
            tenders && tenders.map((t) => {
              return(
                <div className="col-md-4 col-12" data-aos="fade-up" data-aos-delay="100" key={t.tnc_id}>
                  <div className="t-box-main">
                    <div className="tender-box">
                      <h4>{t.tnc_title}</h4>
                      <p>{t.tnc_date}</p>
                      <p>{t.tnc_desc}</p>
                      <a onClick={() => handledownload(t.tnc_pdf)}>Download</a>
                    </div>
                  </div>
                </div>
              )
            })
          }

          {/* <div className="col-md-4 col-12" data-aos="fade-up" data-aos-delay="200">
            <div className="t-box-main">
              <div className="tender-box">
                <h4>Tenders</h4>
                <p>Feed Suppy</p>
                <p>Chick Suppl</p>
                <p>Invitation for Vehicle on Contract</p>
                <p>Appointment of External Auditor</p>
                <a href='#'>View More</a>
              </div>
            </div>
          </div> */}

          <div className="col-md-4 col-12" data-aos="fade-up" data-aos-delay="300">
            <div className="t-box-main">
              <div className="tender-box">
                <h4>Nearest Store</h4>
                <p>Please select your district from below to find your nearest store</p>
                <InputGroup className="text-form">
                  {/* <Form.Control
                      placeholder="Search..."
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    /> */}
                    <Form.Select id='alloutletsedit-dist' value={search} aria-label="Default select " onChange={(e) => setSearch(e.target.value)}>
                      <option value=''>Select Your District</option>
                      {districts.map((city, index) => {
                        return (
                          <option id={index} key={index} value={city.toLowerCase()}>
                            {city}
                          </option>
                        )
                      })}
                    </Form.Select>
                  <InputGroup.Text onClick={hsndleOutletShow} className='search-btn' id="basic-addon2"><AiOutlineSearch/></InputGroup.Text>                  
                </InputGroup>

                <Toast show={show} onClose={toggleClose} className='outlet-suggestions'>
                  <Toast.Header className='d-flex justify-content-end'></Toast.Header>
                  <Toast.Body>
                    {
                      outlets.length ? outlets.map((o) => {
                        return(
                          <div>{o.out_map}</div>
                        )
                      }) : <div className='text-danger'>No matches found...</div>
                    }
                  </Toast.Body>
                </Toast>
                
              </div>
              {/* <div className="price-box-main">
                <div className="price">
                    <h4>₹ {data && data.current}</h4>
                </div>
                <div className="text">
                    <p>Todays <br/>Chicken Price</p>
                </div>
              </div> */}
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
