import {useState, useEffect} from 'react';
import { getData } from '../../AuxillaryScripts/ApiFetcher'
import { filesurl } from '../../AuxillaryScripts/urls';
import HomeAboutImg from '../../assets/img/home-about-img.jpg';
import { useNavigate } from 'react-router-dom';

export default function HomeAbout() {
  const [ data, setData ] = useState([])
    const to = useNavigate();
  const getSupply = async () => {
    const res = await getData('about');

    if (res.status === 401) {  
      console.log('in the 401');    
    //   TokenModal(200)
      return false;
    } else if(!res || res.status !== 200) {
    //   document.dispatchEvent(selectFail)
      return false;
    }    
    const dta = res.data
    setData(dta)
    // console.log(dta)
  }

  useEffect(() => {
    getSupply();
  }, [])

  const characterLimiter = (str) => {
    if (str.length > 795) {
        return str.slice(0, 795) + '...';
      }
      return str;
  }

  const readMore = () => {
    to('/about');
  }

  return (
    <div className='home-about container-fluid' data-aos="fade-up" data-aos-delay="200">
        <div className='about-container'>
            <div className='row'>
                <div className='col-md-5 col-12'>
                    <div className='about-img'>
                        <img src={data.length ? filesurl + 'AboutUs/' + data[0].about_img : HomeAboutImg}/>
                    </div>
                </div>
                <div className='col-md-7 col-12'>
                    <div className='about-content'>
                        <h4>About Us</h4>
                        <p>
                            {data.length ? characterLimiter(data[0].about_desc) : null}
                        </p>
                        <a onClick={readMore}>Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
