import axios from "axios";
import { getInputs, getAllInps, setAllInpToNull, setAllInpFieldToNull } from "./inputs";
import { Base } from "./urls";

const commonHeader = {
  'Content-Type': 'multipart/form-data'
}

const patch = {
  _method: "PATCH"
}

const put = {
  _method: "PUT"
}

// const callApi = async (url, method, data = null, headers = null, params = null ) => {
  
//   return await axios({
//     method: method,
//     url: url,
//     data: data,
//     headers: headers,
//     params: params
//   })
//     .then(response => {
//       return response;
//     })
//     .catch(error => {
//       console.error(error);
//       return error.response;
//     });  
// }

const callApi = async (url, method, data = null, headers = null, params = null) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      axios({
        method: method,
        url: url,
        data: data,
        headers: headers,
        params: params
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.error(error);
          resolve(error.response);
        });
    }, 1000); // Delay of 1000 milliseconds (1 second)
  });
};


const setForm = (cmpid) => {
  let formData = new FormData();

  let data = getInputs(cmpid);

  if (data) {
    for (const x in data) {
      formData.append(x, data[x]);
    }
  } else {
    return false;
  }
  return formData;
}

const tocken = () => sessionStorage.getItem('tkn')

const Headers = (hdrs, form = true) => {
  const hed = hdrs ?? {};
  hed['Authorization'] = "bearer " + tocken();

  const hearders = form ? Object.assign({}, hed, commonHeader) : hed;
  return hearders;
}

const paramFinder = (p, comp) => {
  if(p === 1)
    return getAllInps(comp);
  else if(Array.isArray(p))
    return {...getAllInps(comp), ...p[0]};
  else if(p)
    return p;
  else
    return null;
}



const postRequest = async (url, fdta = null, comp = false, param = null, hdrs = null) => {
  const data = paramFinder(fdta, comp);
  
  const h = Headers(hdrs);

  return await callApi((Base() + url), "post", data, h, param);   
};

const getData = async (url, comp = false, params = null, hdrs = null) => {
  const pars = paramFinder(params, comp)

  const h = Headers(hdrs, false);

  return await callApi((Base() + url), "get", null, h, pars)
};

const postData = async (url, comp = false, fdta, hdrs = null) => {
  return await postRequest(url, fdta, comp, null, hdrs);
};

const postPatch = async (url, comp = false, fdta, hdrs = null) => {  
  return await postRequest(url, fdta, comp, patch, hdrs);
};

const postPut = async (url, comp = false, fdta, hdrs = null) => {
  return await postRequest(url, fdta, comp, put, hdrs);
};

const putData = (url, data, hdrs = null) => { 
  let formData = new FormData();
  formData.append('img', data)
  return axios({
    method: 'put',
    url: url,
    params: "nana",
    headers: commonHeader
  })
    .then(response => {
      // console.log(response.data);
      return response;
    })
    .catch(error => {
      console.error(error);
      return false;
    }); 
  // const dta = setForm(data);
  // if (dta) {
  //   return callApi(url, "put", dta, hdrs ?? commonHeader);
  // }else{
  //   return false;
  // }
};

const patchData = (url, data, hdrs = null) => {  
  const dta = setForm(data);
  if (dta) {
    return callApi(url, "patch", dta, hdrs ?? commonHeader);
  }else{
    return false;
  }
};

const deleteData = async (url, comp = false, data, hdrs = null) => {  
  const pars = paramFinder(data, comp);
  
  const h = Headers(hdrs, false);
  
  return await callApi((Base() + url), "delete", pars, h);
};

export { getData, postData, putData, patchData, postPatch, postPut, deleteData };
