import {useState, useEffect} from 'react';
import Footer from '../../views/footer/footer';
import Header from '../../views/header/header';
import NavbarHeader from '../navbar/navbar';
import { Button, Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import {FaEnvelope, FaMapMarkerAlt, FaPhoneAlt} from 'react-icons/fa';

import { postData, getData } from '../../AuxillaryScripts/ApiFetcher';

export default function ContactMain() {

    useEffect(() => {
        getCOntact()
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

    
    const [data, setData] = useState(null)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [msg, setMsg] = useState('')
    const [spin, setSpin] = useState(false)
    const [success, setSuccess] = useState(false)
    const [fail, setFail] = useState(false)
    const closeloaderMdl = new CustomEvent('close-modal', { detail: {}});

    const getCOntact = async () => {
        const res = await getData('contact');
    
         
        const dta = res.data
        setData(dta)
        // console.log(dta)
        document.dispatchEvent(closeloaderMdl)   
    }

    const enquire = async () => {
        const res = await postData('enquiry', false, {
            mail: email,
            nme: name,
            msg: msg,
            phn: phone
        });
        
        setName('')
        setEmail('')
        setPhone('')
        setMsg('')
        if (res.status === 401) {  
            console.log('in the 401');    
            //   TokenModal(200)
            setSpin(false)
            return false;
        } else if(!res || res.status !== 201) {
            //   document.dispatchEvent(selectFail)
            setSpin(false)
            return false;
        }    
        setSpin(false)
        return true;
    }

    const hadleSubmit = async () => {
        setSpin(true);

        const stat = await enquire();

        if (stat)
            setSuccess(true)
        else
            setFail(true)
    }

    useEffect(() => {
        success && setTimeout(() => {
            setSuccess(false)
        }, 2000);
    }, [success])

    useEffect(() => {
        fail && setTimeout(() => {
            setFail(false)
        }, 2000);
    }, [fail])

  return (
    <div>
        <Header/>
        <NavbarHeader/>

        <div className='about-banner-section container-fluid'>
          <h1>Contact Us</h1>
        </div>

        <div className='contact-main container-fluid'>
            <div className='contact-container'>
                <div className='row'>
                    <div className='col-md-6 col-12 m-auto'>
                        <div className='address'>
                            <div className='box'>
                                <div className='icon'>
                                    <FaMapMarkerAlt/>
                                </div>
                                <div className='content'>
                                    <h4>Our Address</h4>
                                    <p>{data && data.address}</p>
                                </div>
                            </div>
                            <div className='box'>
                                <div className='icon'>
                                    <FaPhoneAlt/>
                                </div>
                                <div className='content'>
                                    <h4>Call Us On</h4>
                                    <p>{data && data.phone}</p>
                                </div>
                            </div>
                            <div className='box'>
                                <div className='icon'>
                                    <FaEnvelope/>
                                </div>
                                <div className='content'>
                                    <h4>Email Us</h4>
                                    <p>{data && data.mail}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-6 col-12'>
                        <div className='form-box'>
                            <h4>Enquiry</h4>
                            <Form>
                                <Form.Control value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Your name" />
                                <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email" />
                                <Form.Control value={phone} onChange={(e) => setPhone(e.target.value)} type="tel" placeholder="Phone" />
                                <Form.Control value={msg} onChange={(e) => setMsg(e.target.value)} type="textarea" placeholder="Message" rows="4" cols="50"/>

                                {success ? <Button className='success-btn'>
                                    Success
                                </Button> : fail ? <Button className='failed-btn'>
                                    Try again in 2 seconds
                                </Button> : <Button className='enq-submit-btn' onClick={hadleSubmit}>
                                    {spin ? <Spinner animation="border" size='sm' className='enq-spin' /> : 'Submit'}
                                </Button> }
                            </Form>
                        </div>
                    </div>

                    <div className='col-12'>
                        <div className='iframe-box'>                            
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3945.7590987665644!2d76.9306801!3d8.5227539!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba43ca1fa1ca24d%3A0xa41ca46f04a94e44!2sKudumbashree%20State%20Mission!5e0!3m2!1sen!2sin!4v1690623820877!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer/>

    </div>
  )
}
