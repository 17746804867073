import {useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getData } from '../../AuxillaryScripts/ApiFetcher'
import { filesurl } from '../../AuxillaryScripts/urls';
import Rpostimg1 from '../../assets/img/rp1.jpg';
import Rpostimg2 from '../../assets/img/rp2.jpg';
import Rpostimg3 from '../../assets/img/rp3.jpg';

export default function HomeEvents() {
    const [ data, setData ] = useState([])
  const to = useNavigate()
  const closeloaderMdl = new CustomEvent('close-modal', { detail: {}});

  const getSupply = async () => {
    const res = await getData('nnehome', false, {type: 'e', limit: 3});
    // document.dispatchEvent(closeloaderMdl)

    if (res.status === 401) {  
      console.log('in the 401');    
    //   TokenModal(200)
      return false;
    } else if(!res || res.status !== 200) {
    //   document.dispatchEvent(selectFail)
      return false;
    }    
    const dta = res.data
    setData(dta)
    // console.log(dta)
  }
  useEffect(() => {
    getSupply();
  }, [])

  const characterLimiter = (str) => {
    if (str.length > 80) {
        return str.slice(0, 80) + '...';
      }
      return str;
  }

  const toNewsDetail = (data) => {
    to('/eventsdetail', {state: {data: data}})
  }
  return (
    <div className='home-recent-posts container-fluid'>
        <div className='posts-container'>
            <div className='row'>
                <h4>Events</h4>
                {data.length &&  <div className='col-md-4 col-12' data-aos="fade-up" data-aos-delay="100">
                    <a onClick={() => toNewsDetail(data[0])}>
                        <div className='post-box'>
                            <div className='img-box'>
                                {/* <img src={filesurl + 'NewsNEvents/' + data[0].nne_img} alt=''/> */}
                                {data[0].nne_img_type == 1 ? 
                                <img src={filesurl + 'NewsNEvents/' + data[0].nne_img} alt=''/> : 
                                <iframe className='w-100 h-' src={data[0].nne_img} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                }
                                <h6>{data[0].nne_date.split('-').reverse().join('-')}</h6>
                            </div>
                            <p>{characterLimiter(data[0].nne_title)}</p>
                        </div>
                    </a>
                </div>}
                {data.length >= 2 &&  <div className='col-md-4 col-12' data-aos="fade-up" data-aos-delay="200">
                    <a onClick={() => toNewsDetail(data[1])}>
                        <div className='post-box'>
                            <div className='img-box'>
                                {/* <img src={filesurl + 'NewsNEvents/' + data[1].nne_img} alt=''/> */}
                                {data[1].nne_img_type == 1 ? 
                                <img src={filesurl + 'NewsNEvents/' + data[1].nne_img} alt=''/> : 
                                <iframe className='w-100 h-' src={data[1].nne_img} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                }
                                <h6>{data[1].nne_date.split('-').reverse().join('-')}</h6>
                            </div>
                            <p>{characterLimiter(data[1].nne_title)}</p>
                        </div>
                    </a>
                </div>}
                {data.length == 3 &&  <div className='col-md-4 col-12' data-aos="fade-up" data-aos-delay="300">
                    <a onClick={() => toNewsDetail(data[2])}>
                        <div className='post-box'>
                            <div className='img-box'>
                                {/* <img src={filesurl + 'NewsNEvents/' + data[2].nne_img} alt=''/> */}
                                {data[2].nne_img_type == 1 ? 
                                <img src={filesurl + 'NewsNEvents/' + data[2].nne_img} alt=''/> : 
                                <iframe className='w-100 h-' src={data[2].nne_img} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                }
                                <h6>{data[2].nne_date.split('-').reverse().join('-')}</h6>
                            </div>
                            <p>{characterLimiter(data[2].nne_title)}</p>
                        </div>
                    </a>
                </div>}
            </div>
        </div>
    </div>
  )
}
