import React from 'react';
import Header from '../../views/header/header';
import NavbarHeader from '../navbar/navbar';
import HomeNewsOne from '../../assets/img/news1.jpg';
import HomeNewsTwo from '../../assets/img/news2.jpg';
import { Link } from 'react-router-dom';
import Footer from '../../views/footer/footer';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { filesurl } from '../../AuxillaryScripts/urls';
import { getData } from '../../AuxillaryScripts/ApiFetcher'
import StoriesImgOne from '../../assets/img/s-stories.jpeg';

export default function OutletSuccessStoriesDetail() {

    const loc = useLocation();

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

      const [ data, setData ] = useState([])
  const to = useNavigate()

  const getSupply = async () => {
    const res = await getData('sslimit', false, {type: 'o'});

       
    const dta = res.data
    setData(dta)
    const closeloaderMdl = new CustomEvent('close-modal', { detail: {}});
    document.dispatchEvent(closeloaderMdl)
  }
  useEffect(() => {
    getSupply();
  }, [])

  const characterLimiter = (str) => {
    if (str.length > 80) {
        return str.slice(0, 80) + '...';
      }
      return str;
  }

  const toNewsDetail = (data) => {
    to('/outletstoriesdetail', {state: {data: data}})
  }

  return (
    <div>
        <Header/>
        <NavbarHeader/>
        <div className='about-banner-section container-fluid'>
          <h1>Farm Success Stories</h1>
        </div>
        <div className='news-detail-main container-fluid'>
            <div className='news-container'>
                <div className='row'>
                    <div className='col-md-8 col-12'>
                        <div className='news-content'>
                            <h4>{loc.state.data.ss_title}</h4>
                            <p>{loc.state.data.ss_desc}</p>                            
                        </div>
                        <div className='news-img'>
                            <img src={filesurl + 'SuccessStories/' + loc.state.data.ss_img} alt=''/>
                        </div>
                    </div>
                    <div className='col-md-4 col-12'>
                        <div className='recent-news'>
                            <div className='row'>
                                {
                                    data.length && <div className='col-12'>
                                        <div onClick={() => toNewsDetail(data[0])}>
                                            <div className='inner-box'>
                                            <img src={filesurl + 'SuccessStories/' + data[0].ss_img} alt=''/>
                                            <p>{characterLimiter(data[0].ss_title)}</p>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {data.length == 2 && <div className='col-12'>
                                        <div onClick={() => toNewsDetail(data[1])}>
                                            <div className='inner-box'>
                                            <img src={filesurl + 'SuccessStories/' + data[1].ss_img} alt=''/>
                                            <p>{characterLimiter(data[1].ss_title)}</p>
                                            </div>
                                        </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
