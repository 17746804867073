import {useState, useEffect} from 'react';
import Footer from '../../views/footer/footer';
import Header from '../../views/header/header';
import NavbarHeader from '../navbar/navbar';
import FarmGimg from '../../assets/img/farm-g.jpg';
import FigImg1 from '../../assets/img/figure73.png';
import FigImg2 from '../../assets/img/figure74.png';

import { getData } from '../../AuxillaryScripts/ApiFetcher'
import { filesurl } from '../../AuxillaryScripts/urls';

export default function FarmGuideline() {
    const [gdls, setGdl] = useState(null)
    const [ criterias, setCriterias ] = useState([])
    const [ certificates, setCertificates ] = useState([])

    useEffect(() => {
        getGdl();
        getCrits();
        getCerts();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    const getGdl = async () => {
        const res = await getData('guidelines', false, {type: 'f'});

        
        const dta = res.data[0]
        setGdl(dta)
        const closeloaderMdl = new CustomEvent('close-modal', { detail: {}});
        document.dispatchEvent(closeloaderMdl) 
    }

    const getCrits = async () => {
        const res = await getData('gdlcrits', false, {type: 'f'});

        if (res.status === 401) {  
            console.log('in the 401');    
            //   TokenModal(200)
            return false;
        } else if(!res || res.status !== 200) {
            //   document.dispatchEvent(selectFail)
            return true;
        }    
        const dta = res.data
        setCriterias(dta)
        // console.log(dta)
        return true;
    }

    const getCerts = async () => {
        const res = await getData('gdlcerts', false, {type: 'f'});

        if (res.status === 401) {  
            console.log('in the 401');    
            //   TokenModal(200)
            return false;
        } else if(!res || res.status !== 200) {
            //   document.dispatchEvent(selectFail)
            return true;
        }    
        const dta = res.data
        setCertificates(dta)
        // console.log(dta)
        return true;
    }

  return (
    <div>
        <Header/>
        <NavbarHeader/>

        <div className='about-banner-section container-fluid'>
          <h1>Farm Guidelines</h1>
        </div>

        <div className='main-farm-guidelines container-fluid p-0'>
            <div className='selection-content'>
                <div className='custom-container'>
                    <h4>Selection of Farms</h4>
                    <div className='row'>
                        <div className='col-md-8 col-12'>
                            {criterias.length && criterias.map((c, i) => {                                
                                return(
                                    <div className='inner-box' key={i}>
                                        <h2>0{i + 1}</h2>
                                        <p>{c.gcr}</p>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='col-md-4 col-12'>
                            <div className='img'>
                                <img className='figimg1' src={FigImg1}/>
                                <img className='figimg2' src={FigImg2}/>
                                <img className='mainimg' src={gdls && filesurl + 'Guidelines/' + gdls.gdl_img}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='cert-benefits-section'>
                <div className="custom-container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="certificate-box">
                                <h4>Certificates for starting Farms</h4>
                                <ul>
                                    {
                                        certificates.length && certificates.map((c) => {
                                            return(
                                                <li>{c.gc}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="benefits-box">
                                <h4>Benefits to Kerala Chicken Farmers</h4>
                                <p>{gdls && gdls.gdl_benafit_disc}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer/>

    </div>
  )
}
