import {useState, useEffect} from 'react';
import Header from '../../views/header/header';
import NavbarHeader from '../navbar/navbar';
import Footer from '../../views/footer/footer';
import axios from 'axios';
import { Form, Button, Spinner } from 'react-bootstrap';

import { getData, postData } from '../../AuxillaryScripts/ApiFetcher'
import { filesurl } from '../../AuxillaryScripts/urls';
import fileDownload from 'js-file-download'
import { FileValidator } from '../../AuxillaryScripts/FileValidator';

export default function CareerNotifications() {

    const [ tenders, setTenders ] = useState([])
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [resume, setResume] = useState('');
    const [spin, setSpin] = useState(false)
    const [success, setSuccess] = useState(false)
    const [fail, setFail] = useState(false)
    const closeloaderMdl = new CustomEvent('close-modal', { detail: {}});

    useEffect(() => {
        getTenders();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    const getTenders = async () => {
        const res = await getData('tnc', false, {type: 'cn'});

        
        const dta = res.data
        setTenders(dta)
        document.dispatchEvent(closeloaderMdl)   
    }

    const handledownload = (filename) => {
    axios.get(filesurl + "TendersNCareers/" + filename, {
        responseType: 'blob',
    })
    .then((res) => {
        fileDownload(res.data, filename)
    })
    .catch(function (error) {
        console.log(error);
    });  
    }

    const submitResume = async () => {
        const res = await postData('ressumes', false, {
            mail: email,
            nme: name,
            pdf: resume,
            phn: phone
        });
        
        setName('')
        setEmail('')
        setPhone('')        
        setResume(null)
        if (res.status === 401) {  
            console.log('in the 401');    
            //   TokenModal(200)
            setSpin(false)
            return false;
        } else if(!res || res.status !== 201) {
            //   document.dispatchEvent(selectFail)
            setSpin(false)
            return false;
        }    
        setSpin(false)
        return true;
    }

    const hadleSubmit = async () => {
        setSpin(true);

        const stat = await submitResume();

        if (stat)
            setSuccess(true)
        else
            setFail(true)
    }

    useEffect(() => {
        success && setTimeout(() => {
            setSuccess(false)
        }, 2000);
    }, [success])

    useEffect(() => {
        fail && setTimeout(() => {
            setFail(false)
        }, 2000);
    }, [fail])

    useEffect(() => {
        console.log(resume);
    }, [resume])

  return (
    <div>
        <Header/>
        <NavbarHeader/>

        <div className='about-banner-section container-fluid'>
          <h1>Careers</h1>
        </div>
        
        <div className='careers-main container-fluid'>
            <div className='career-container'>
                <div className='row'>
                    <div className='col-md-8 col-12'>
                        <div className='row'>
                        {
                            tenders && tenders.map((t) => {
                                return(
                                    <div className="col-md-6 col-12">
                                        <div className="t-box-main">
                                            <div className="tender-box">
                                            <h4>{t.tnc_title}</h4>
                                            <p>{t.tnc_date.split('-').reverse().join('-')}</p>
                                            <p>{t.tnc_desc}</p>
                                            <a onClick={() => handledownload(t.tnc_pdf)}>Download Notification</a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                            
                        </div>
                    </div>
                    <div className='col-md-4 col-12'>
                        <div className='career-form-box'>
                            <h4>Send Your Resume</h4>
                            <Form>
                                <Form.Control value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Your name" />
                                <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email" />
                                <Form.Control value={phone} onChange={(e) => setPhone(e.target.value)} type="tel" placeholder="Phone" />
                                <Form.Control onChange={(e) => {
                                    if (e.target.files) {
                                        if (FileValidator(e.target.files[0])) {
                                            setResume(e.target.files[0])
                                        } else {
                                            alert('File size exceeds 1MB limit.')
                                        }
                                    }
                                }} accept=".pdf" type="file" />
                                
                                {success ? <Button className='success-btn'>
                                    Success
                                </Button> : fail ? <Button className='failed-btn'>
                                    Try again in 2 seconds
                                </Button> : <Button className='enq-submit-btn' onClick={hadleSubmit}>
                                    {spin ? <Spinner animation="border" size='sm' className='enq-spin' /> : 'Submit'}
                                </Button> }
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer/>

    </div>
  )
}
