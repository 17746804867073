import './App.css';
import { BrowserRouter as Router,Routes, Route, Link, HashRouter, BrowserRouter } from 'react-router-dom';
import AboutMain from './components/about/about';
import Home from './components/home/home';
import TenderNewMain from './components/tenders-new/tenders-new';
import OutletsMain from './components/outlets/outlets';
import ReportsMain from './components/reports/reports';
import NewsMain from './components/news/news';
import NewsDetailMain from './components/newsdetail/newsdetail';
import ContactMain from './components/contact/contact';
import DirectorBoardMain from './components/director-board/director-board';
import FarmGuideline from './components/farm-guidelines/farm-guideline';
import BrochureDownloadMain from './components/brochure/brochure';
import LeafletDownloadMain from './components/leaflets/leaflets';
import CareerNotifications from './components/careers-notifications/careers-notifications';
import CareerRanklist from './components/careers-ranklist/careers-ranklist';
import TenderClosedMain from './components/tenders-closed/tenders-closed';
import OutletGuideline from './components/outlet-guidelines/outlet-guideline';
import FarmApplicationDownload from './components/farm-application/farm-application';
import FarmGalleryMain from './components/farm-gallery/farm-gallery';
import FarmGalleryDetailMain from './components/farm-gallery-detail/farm-gallery-detail';
import OutletGalleryMain from './components/outlet-gallery/outlet-gallery';
import OutletGalleryDetailMain from './components/outlet-gallery-detail/outlet-gallery-detail';
import EventsMain from './components/events/events';
import EventsDetailMain from './components/eventsdetail/eventsdetail';
import FarmSuccessStories from './components/farm-success-stories/farm-success-stories';
import FarmSuccessStoriesDetail from './components/farm-stories-detail/farm-stories-detail';
import OutletSuccessStories from './components/outlet-success-stories/outlet-success-stories';
import OutletSuccessStoriesDetail from './components/outlet-stories-detail/outlet-stories-detail';
import HandbooksMain from './components/handbooks/handbooks';
import OutletApplicationDownload from './components/outlet-application/outlet-application';
import MainLayout from './views/MainLayout';

function App() {
  return (
    <>      
      <BrowserRouter >
        <Routes>
          <Route exact path='*' element={< MainLayout />}></Route>
          {/* <Route exact path='/' element={< Home />}></Route>
          <Route exact path='/about' element={<AboutMain/>}></Route>
          <Route exact path='/tendersnew' element={< TenderNewMain />}></Route>
          <Route exact path='/farmgallery' element={< FarmGalleryMain />}></Route>
          <Route exact path='/farmgallerydetail' element={< FarmGalleryDetailMain />}></Route>
          <Route exact path='/outlets' element={< OutletsMain />}></Route>
          <Route exact path='/reports' element={< ReportsMain />}></Route>
          <Route exact path='/career-notifications' element={< CareerNotifications />}></Route>
          <Route exact path='/news' element={< NewsMain />}></Route>
          <Route exact path='/newsdetail' element={< NewsDetailMain />}></Route>
          <Route exact path='/contact' element={< ContactMain />}></Route>
          <Route exact path='/directboardmembers' element={< DirectorBoardMain />}></Route>
          <Route exact path='/farmguidelines' element={< FarmGuideline />}></Route>
          <Route exact path='/brochures' element={< BrochureDownloadMain />}></Route>
          <Route exact path='/leaflet' element={<LeafletDownloadMain/>}></Route>
          <Route exact path='/career-ranklist' element={<CareerRanklist/>}></Route>
          <Route exact path='/tendersclosed' element={<TenderClosedMain/>}></Route>
          <Route exact path='/outletguidelines' element={<OutletGuideline/>}></Route>
          <Route exact path='/applicationforfarm' element={<FarmApplicationDownload/>}></Route>
          <Route exact path='/outletgallery' element={<OutletGalleryMain/>}></Route>
          <Route exact path='/outletgallerydetail' element={<OutletGalleryDetailMain/>}></Route>
          <Route exact path='/events' element={<EventsMain/>}></Route>
          <Route exact path='/eventsdetail' element={<EventsDetailMain/>}></Route>
          <Route exact path='/farmsuccessstories' element={<FarmSuccessStories/>}></Route>
          <Route exact path='/farmstoriesdetail' element={<FarmSuccessStoriesDetail/>}></Route>
          <Route exact path='/outletsuccessstories' element={<OutletSuccessStories/>}></Route>
          <Route exact path='/outletstoriesdetail' element={<OutletSuccessStoriesDetail/>}></Route>
          <Route exact path='/farmhandbooks' element={<HandbooksMain/>}></Route>
          <Route exact path='/outletapplication' element={<OutletApplicationDownload/>}></Route> */}
        </Routes>
      </BrowserRouter>      
    </>
  );
}

export default App;
