import {useState, useEffect} from 'react';
import Header from '../../views/header/header';
import NavbarHeader from '../navbar/navbar';
import HomeSpecialitiesImg from '../../assets/img/home-specialities.jpg';
import Footer from '../../views/footer/footer';
import ModalImage from 'react-modal-image';
import { useLocation } from 'react-router-dom';

import { getData } from '../../AuxillaryScripts/ApiFetcher'
import { filesurl } from '../../AuxillaryScripts/urls';

export default function FarmGalleryDetailMain() {

    const [ tenders, setTenders ] = useState([])
    const loc = useLocation();
    const closeloaderMdl = new CustomEvent('close-modal', { detail: {}});

    useEffect(() => {
        getTenders();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    const getTenders = async () => {
        const res = await getData('album', false, {id: loc.state.data});
    
        const dta = res.data
        setTenders(dta)
        document.dispatchEvent(closeloaderMdl)        
    }

  return (
    <div>
        <Header/>
        <NavbarHeader/>

        <div className='about-banner-section container-fluid'>
            <h1>Farm Gallery</h1>
        </div>

        <div className='gallery-detail-main container-fluid'>
            <div className='gallery-container'>
                <div className='row'>
                    {
                        tenders.length && tenders.map((t) => {
                            return(
                                <div className='col-md-3 col-12' key={t.alb_id}>
                                    <div className='gallery-box'>
                                        <ModalImage
                                        small={filesurl + 'Album/' + t.alb_img}
                                        large={filesurl + 'Album/' + t.alb_img}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>

        <Footer/>

    </div>
  )
}
