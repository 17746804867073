import React, { useState } from 'react';
import HomeCarousel from '../../views/carousel/carousel';
import Headerprice from '../../views/header-price/header-price';
import Header from '../../views/header/header';
import HomeTender from '../../views/home-tender/home-tender';
import NavbarHeader from '../navbar/navbar';
import HomeAbout from '../../views/home-about/home-about';
import HomeTeam from '../../views/home-team/home-team';
import HomeSpecialities from '../../views/home-specialities/home-specialities';
import HomeForm from '../../views/home-form/home-form';
import HomeNews from '../../views/home-news/home-news';
import HomeEvents from '../../views/home-events/home-events';
import Footer from '../../views/footer/footer';
import HomeSliderPrice from '../../views/home-slider-price/home-slider-price';
import HomeMarquee from '../../views/home-marquee/home-marquee';
import { useEffect } from 'react';

export default function Home() {

  const [ rend, setRend ] = useState(false);

  useEffect(() => {
    // 👇️ scroll to top on page load
    setTimeout(() => {
      setRend(true)
    }, 1000);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);



  return (
    <>
      {
        rend ? 
        <div>
          <Header/>
          <NavbarHeader/>
          <Headerprice/>
          <div className='carousel-main-section'>
            <div className='row'>
              <div className='col-md-9 col-12 p-0'>
                <HomeCarousel/>
              </div>
              <div className='col-md-3 col-12 p-0'>
                <HomeSliderPrice/>
                <HomeMarquee/>
              </div>
            </div>
          </div>
          <HomeTender/>
          <HomeAbout/>
          <HomeTeam/>
          {/* <HomeSpecialities/> */}
          <HomeForm/>
          <HomeNews/>
          <HomeEvents/>
          <Footer/>
        </div> : 
        <div className='home-rend-hinderer'></div>
      }
    </>
  );
}

